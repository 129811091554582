import { useEffect } from "react";
import { useAuth } from "oidc-react";
import { useLocation } from "react-router-dom";

export function useAuthRedirect() {
  const auth = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!auth.userData) {
      const returnTo = location.pathname + location.search;

      if (!returnTo.startsWith("/auth-callback")) {
        sessionStorage.setItem("returnTo", returnTo);
      }
    }
  }, [auth.userData, location]);

  return auth.userData;
}
