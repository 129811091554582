import { useMutation, useQuery } from "react-query";
import { EvChargingSession } from "./models/ev/ev-charging-session";
import { evChargingService } from "../services/ev-charging.service";
import {
  EvChargingSessionPlan,
  EvChargingSessionPlanNew,
} from "./models/ev/ev-charging-session-plan";
import { EvAllowedCar } from "./models/ev/ev-allowed-car";

export function useEvAllowedCarsQuery(installationId: number) {
  return useQuery<EvAllowedCar[]>(["ev-allowed-cars", installationId], () =>
    evChargingService.getEvAllowedCars(installationId)
  );
}

export function useEvChargingSessionsQuery(
  installationId: number,
  from: Date,
  to: Date
) {
  return useQuery<EvChargingSession[]>(
    ["ev-charging-sessions", installationId, from, to],
    () => evChargingService.getEvChargingSessions(installationId, from, to),
    { enabled: Boolean(installationId) }
  );
}

export function useOverlappingEvChargingSessionsQuery(
  installationId: number,
  from: Date,
  to: Date
) {
  return useQuery<EvChargingSession[]>(
    ["ev-charging-sessions", installationId, from, to],
    () =>
      evChargingService.getOverlappingEvChargingSessions(
        installationId,
        from,
        to
      ),
    { enabled: Boolean(installationId) }
  );
}

export function useEvChargingSessionPlansQuery(
  installationId: number,
  from: Date,
  to: Date
) {
  return useQuery<EvChargingSessionPlan[]>(
    ["ev-charging-session-plans", installationId, from, to],
    () => evChargingService.getEvChargingSessionPlans(installationId, from, to),
    { enabled: Boolean(installationId) }
  );
}

export function useAllEvChargingSessionPlansQuery(
  installationId: number,
  from: Date,
  to: Date
) {
  return useQuery<EvChargingSessionPlan[]>(
    [
      "ev-charging-session-plans",
      "ev-charging-session-plans-all",
      installationId,
      from,
      to,
    ],
    () =>
      evChargingService.getAllEvChargingSessionPlans(installationId, from, to),
    { enabled: Boolean(installationId) }
  );
}

export function useCreateEvChargingSessionPlanMutation(installationId: number) {
  return useMutation((plan: EvChargingSessionPlanNew) =>
    evChargingService.createEvChargingSessionPlan(installationId, plan)
  );
}

export function useUpdateEvChargingSessionPlanMutation(installationId: number) {
  return useMutation((plan: EvChargingSessionPlan) =>
    evChargingService.updateEvChargingSessionPlan(installationId, plan)
  );
}

export function useDeleteEvChargingSessionPlanMutation(installationId: number) {
  return useMutation((planId: number) =>
    evChargingService.deleteEvChargingSessionPlan(installationId, planId)
  );
}
