import { DataTable } from "primereact/datatable";
import { Loader } from "../ui/Loader";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useInstallationSelector } from "../ui/InstallationContext";
import {
  useChargersQuery,
  useCreateChargerMutation,
  useDeleteChargerMutation,
  useUpdateChargerMutation,
} from "../../queries/ev-configuration.query";
import { Button } from "primereact/button";
import { ChargerConfiguration } from "../../queries/models/ev-configuration/charger-configuration.model";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { t } from "i18next";
import { useToast } from "../ui/ToastContext";
import { useQueryClient } from "react-query";
import { ChargerEditForm } from "./ChargerEditForm";

export function ChargersConfiguration() {
  const { selectedInstallationId } = useInstallationSelector();
  const { data, isLoading } = useChargersQuery(selectedInstallationId);

  const [selectedCharger, setSelectedCharger] = useState<
    ChargerConfiguration | undefined
  >();
  const [chargerDialogVisible, setChargerDialogVisible] = useState(false);

  const chargerSaveMutation = useUpdateChargerMutation(selectedInstallationId);

  const chargerCreateMutation = useCreateChargerMutation(
    selectedInstallationId
  );

  const chargerDeleteMutation = useDeleteChargerMutation(
    selectedInstallationId
  );

  const queryClient = useQueryClient();

  const toast = useToast();

  function showAddChargerDialog() {
    setSelectedCharger({
      id: 0,
      name: "",
      qrCode: "",
      maxChargingPower: 0,
      pluggedInDeviceAttributeId: 0,
      consumptionDeviceAttributeId: 0,
      energyDeliveredDeviceAttributeId: 0,
      setpointDeviceAttributeId: 0,
      isEnabled: true,
    } as ChargerConfiguration);

    setChargerDialogVisible(true);
  }

  function hideChargerDialog() {
    setSelectedCharger(undefined);
    setChargerDialogVisible(false);
  }

  async function saveCharger() {
    if (selectedCharger) {
      if (!selectedCharger.id) {
        await chargerCreateMutation.mutateAsync(selectedCharger);
        await queryClient.invalidateQueries("chargers");
        await queryClient.invalidateQueries("configuration-chargers");
        hideChargerDialog();
      } else {
        chargerSaveMutation.mutate(selectedCharger, {
          onSuccess: async () => {
            await queryClient.invalidateQueries("chargers");
            await queryClient.invalidateQueries("configuration-chargers");
            hideChargerDialog();
          },
          onError: (error: any) => {
            console.info("Error!", error);
            toast.current?.show({
              severity: "error",
              detail: t(error.response?.data) ?? error.message,
            });
          },
        });
      }
    }
  }

  async function deleteCharger(id: number) {
    confirmDialog({
      message: "Are you sure you want to delete this charger?",
      accept: () => doDeleteDeviceAttribute(id),
    });
  }

  async function doDeleteDeviceAttribute(id: number) {
    await chargerDeleteMutation.mutateAsync(id);
    await queryClient.invalidateQueries("chargers");
    await queryClient.invalidateQueries("configuration-chargers");
  }

  function showEditDialog(id: number) {
    const charger = data?.find((c) => c.id === id);

    if (charger) {
      setSelectedCharger(charger);
      setChargerDialogVisible(true);
    }
  }

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Loader spinner />
      </div>
    );
  }

  return (
    <div>
      {data && (
        <>
          <div>
            <Button
              label="Add Charger"
              className="p-button p-button-success !my-2 !mb-3"
              onClick={showAddChargerDialog}
            />
          </div>
          <div>
            <DataTable
              value={data}
              onRowClick={(e) =>
                showEditDialog((e.data as ChargerConfiguration).id)
              }
              rowClassName={() => "cursor-pointer"}
            >
              <Column field="id" header="Id"></Column>
              <Column field="name" header="Name"></Column>
              <Column field="qrCode" header="QR Code"></Column>
              <Column
                field="maxChargingPower"
                header="Max Charging Power"
              ></Column>
              <Column field="isEnabled" header="Enabled"></Column>
              <Column field="isPublic" header="Public"></Column>
              {/* <Column
                header=""
                body={(rowData) => (
                  <Button
                    icon="pi pi-trash"
                    className="p-button-secondary"
                    onClick={() => deleteCharger(rowData.id)}
                  ></Button>
                )}
              /> */}
            </DataTable>
          </div>
        </>
      )}
      <Dialog
        visible={chargerDialogVisible}
        header="Charger"
        onHide={() => hideChargerDialog()}
      >
        {selectedCharger && (
          <div className="flex flex-col">
            <ChargerEditForm
              value={selectedCharger}
              onChange={(v) => setSelectedCharger(v)}
            />

            <Button
              label={t("common.save")}
              className="p-button p-button-success !mt-2"
              onClick={saveCharger}
            />
          </div>
        )}
      </Dialog>
      <ConfirmDialog />
    </div>
  );
}
