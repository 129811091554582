import { useMutation, useQuery } from "react-query";
import { CarConfiguration } from "./models/car-configuration/car-configuration.model";
import { CreateCar } from "./models/car-configuration/create-car.model";
import { UpdateCar } from "./models/car-configuration/update-car.model";
import { carConfigurationService } from "../services/car-configuration.service";

export function useCarsQuery(userId: number | undefined) {
  return useQuery<CarConfiguration[]>(
    ["configuration-cars", userId],
    () => carConfigurationService.getCars(userId!),
    { enabled: Boolean(userId) }
  );
}

export function useCreateCarMutation(userId: number) {
  return useMutation((data: CreateCar) =>
    carConfigurationService.addCar(userId, data)
  );
}

export function useUpdateCarMutation(userId: number) {
  return useMutation((data: UpdateCar) =>
    carConfigurationService.updateCar(userId, data)
  );
}

export function useDeleteCarMutation(userId: number) {
  return useMutation((id: number) =>
    carConfigurationService.deleteCar(userId, id)
  );
}
