import { TabView, TabPanel } from "primereact/tabview";
import { Chargers } from "../components/public-charging/Chargers";
import { Users } from "../components/public-charging/Users";

export function PublicChargingAdmin() {
  return (
    <div>
      <TabView>
        <TabPanel header="Chargers">
          <Chargers />
        </TabPanel>
        <TabPanel header="Users">
          <Users />
        </TabPanel>
        <TabPanel header="Charging Sessions"></TabPanel>
      </TabView>
    </div>
  );
}
