import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "oidc-react";
import { faPowerOff, faCog } from "@fortawesome/free-solid-svg-icons";
import { InstallationSelector } from "../ui/InstallationSelector";
import { useInstallationSelector } from "../ui/InstallationContext";
import { useWindowSize } from "../../hooks/use-window-size";

import { LanguageDropdown } from "./language-dropdown";
import { useNavigate } from "react-router";

export function TopBarNavigation() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { selectedInstallationId, hasInstallation, setSelectedInstallationId } =
    useInstallationSelector();

  function logout() {
    auth.signOutRedirect();
  }

  function openSettings() {
    navigate("/settings");
  }

  const windowSize = useWindowSize();

  return (
    <div className="px-4 bg-primary h-14 flex items-center fixed w-full z-50">
      <div className="items-center flex">
        <a href="/">
          {windowSize.sm ? (
            <img
              className="w-[260px] p-0 lg:pl-0 lg:p-3"
              src="/logo.svg"
              alt="logo"
            />
          ) : (
            <img
              className="w-[50px] pr-2"
              src="/logo_sign_white.svg"
              alt="logo"
            />
          )}
        </a>
      </div>
      <div className="flex-row-reverse flex w-full items-center">
        <span
          className="flex rounded-full bg-teal-600 w-8 h-8 justify-center items-center cursor-pointer"
          onClick={logout}
        >
          <FontAwesomeIcon icon={faPowerOff} className="text-white" />
        </span>
        <span
          className="flex w-8 h-8 justify-center items-center cursor-pointer mr-2"
          onClick={openSettings}
        >
          <FontAwesomeIcon icon={faCog} className="text-white" />
        </span>
        {hasInstallation && (
          <div className="sm:pr-2 pr-1">
            <InstallationSelector
              selectedInstallationId={selectedInstallationId}
              onInstallationIdChange={setSelectedInstallationId}
            />
          </div>
        )}
        {windowSize.sm && <LanguageDropdown></LanguageDropdown>}
      </div>
    </div>
  );
}
