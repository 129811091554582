import { AdminChargerInfo } from "../queries/models/public-charging/admin-charger-info.model";
import { ChargingStationInvitationInfo } from "../queries/models/public-charging/charging-station-invitation-info.model";
import { ChargingUserInfo } from "../queries/models/public-charging/charging-user-info.model";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class ChargingAdminService extends BaseService {
  async getChargers(installationId: number): Promise<AdminChargerInfo[]> {
    const url = `${this.endpointPath}/${installationId}/chargers`;

    return this.http.get<AdminChargerInfo[]>(url).then((res) => res.data);
  }

  async getUsers(installationId: number): Promise<ChargingUserInfo[]> {
    const url = `${this.endpointPath}/${installationId}/users`;

    return this.http.get<ChargingUserInfo[]>(url).then((res) => res.data);
  }

  async getInvitations(
    installationId: number
  ): Promise<ChargingStationInvitationInfo[]> {
    const url = `${this.endpointPath}/${installationId}/invitations`;

    return this.http
      .get<ChargingStationInvitationInfo[]>(url)
      .then((res) => res.data);
  }

  async inviteUser(installationId: number, email: string): Promise<void> {
    const url = `${this.endpointPath}/${installationId}/invitation`;

    return this.http.post(url, { email }).then((res) => res.data);
  }
}

export const chargingAdminService = new ChargingAdminService(
  API_ENDPOINTS.CHARGING_ADMIN
);
