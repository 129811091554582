import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { useEffect, useState } from "react";
import { DeviceAttributeSelector } from "../components/configuration/io/DeviceAttributeSelector";
import { ContentWithLabel } from "../components/ui/ContentWithLabel";
import { useToast } from "../components/ui/ToastContext";
import {
  useIOConfigurationMutation,
  useIOConfigurationQuery,
} from "../queries/configuration.query";
import { IOConfiguration } from "../queries/models/configuration/io-configuration";
import { ShowFor } from "../components/ui/ShowFor";
import { Separator } from "../components/ui/Separator";
import { RoundedButtonSelector } from "../components/ui/RoundedButtonSelector";
import { SolarPanelsConfiguration } from "../queries/models/configuration/solar-panels-configuration";
import { SolarPanelsConfigurationForm } from "../components/configuration/io/SolarPanelsConfigurationForm";
import { useInstallationSelector } from "../components/ui/InstallationContext";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../hooks/use-window-size";
import { RoundedButton } from "../components/ui/RoundedButton";
import { classNames } from "primereact/utils";

export function IO() {
  const { t } = useTranslation();
  const { selectedInstallationId } = useInstallationSelector();
  const windowSize = useWindowSize();

  const [ioConfiguration, setIoConfiguration] = useState<IOConfiguration>();

  const [
    selectedSolarPanelsConfigurationIndex,
    setSelectedSolarPanelsConfigurationIndex,
  ] = useState<number>(-1);

  const { data } = useIOConfigurationQuery(selectedInstallationId ?? 0);
  const ioConfigurationMutation = useIOConfigurationMutation(
    selectedInstallationId ?? 0
  );

  const toast = useToast();

  useEffect(() => {
    if (data) {
      if (data.solarPanelsConfigurations === null) {
        data.solarPanelsConfigurations = [];
      }

      setIoConfiguration(data);

      setSelectedSolarPanelsConfigurationIndex(
        (data?.solarPanelsConfigurations.length ?? -1) > 0 ? 0 : -1
      );
    }
  }, [data]);

  async function saveChanges() {
    await ioConfigurationMutation.mutateAsync(ioConfiguration!);
    toast.current?.show({
      severity: "success",
      content: t("configuration.configurationSaved"),
    });
  }

  const setConfigurationProperty: <T extends keyof IOConfiguration>(
    property: T,
    value: IOConfiguration[T] | null
  ) => void = (property, value) => {
    setIoConfiguration({
      ...ioConfiguration!,
      [property]: value,
    });
  };

  function setSolarPanelsConfiguration(
    configuration: SolarPanelsConfiguration,
    index: number
  ) {
    if (ioConfiguration) {
      Object.assign(
        ioConfiguration.solarPanelsConfigurations[index],
        configuration
      );
      setIoConfiguration({ ...ioConfiguration });
      setSelectedSolarPanelsConfigurationIndex(index);
    }
  }

  function addSolarPanels() {
    if (ioConfiguration) {
      ioConfiguration.solarPanelsConfigurations.push({
        name: "PV",
        longitude: 0,
        latitude: 0,
        azimuth: 0,
        declination: 0,
        nominalPower: 0,
      } as SolarPanelsConfiguration);
      setIoConfiguration(ioConfiguration);
      setSelectedSolarPanelsConfigurationIndex(
        ioConfiguration.solarPanelsConfigurations.length - 1
      );
    }
  }

  function deleteSolarPanels(index: number) {
    if (ioConfiguration) {
      if (index > -1) {
        ioConfiguration.solarPanelsConfigurations.splice(index, 1);
        setIoConfiguration(ioConfiguration);
        setSelectedSolarPanelsConfigurationIndex(index - 1);

        if (ioConfiguration.solarPanelsConfigurations.length === 0) {
          ioConfiguration.solarPanelsForecastForControlDeviceAttributeId = 0;
          ioConfiguration.solarPanelsPowerDeviceAttributeId = 0;
        }
      }
    }
  }

  return (
    <div className="m-2">
      <div
        className={classNames(
          windowSize.sm
            ? "flex flex-row justify-between lg:mb-3 mb-0"
            : "fixed bottom-20 right-6 z-[1]"
        )}
      >
        <ShowFor permission="ConfigurationEdit">
          <Button
            className={classNames("p-button", !windowSize.sm && "shadow-md")}
            onClick={saveChanges}
          >
            {t("common.saveChanges")}
          </Button>
        </ShowFor>
      </div>
      {ioConfiguration && (
        <div>
          <div className="flex flex-wrap">
            <div className="grow basis-0 p-2 mt-12">
              <Fieldset className="relative mt-6">
                <div className="flex justify-center mt-[-75px]">
                  <img
                    className="w-24"
                    src="/images/PV_panel.png"
                    alt={t("common.solarPanels")}
                  />
                </div>
                <div className="flex justify-center text-primary font-bold text-xl">
                  {t("common.solarPanels")}
                </div>
                <div className="flex justify-center mb-2">
                  <RoundedButtonSelector
                    selectedIndex={selectedSolarPanelsConfigurationIndex}
                    options={ioConfiguration.solarPanelsConfigurations}
                    onSelected={(i) =>
                      setSelectedSolarPanelsConfigurationIndex(i)
                    }
                    onAddClicked={addSolarPanels}
                  />
                </div>
                {selectedInstallationId &&
                  selectedSolarPanelsConfigurationIndex >= 0 && (
                    <SolarPanelsConfigurationForm
                      installationId={selectedInstallationId}
                      configuration={
                        ioConfiguration.solarPanelsConfigurations[
                          selectedSolarPanelsConfigurationIndex
                        ]
                      }
                      showName={
                        ioConfiguration.solarPanelsConfigurations.length > 1
                      }
                      onChange={(e) =>
                        setSolarPanelsConfiguration(
                          e,
                          selectedSolarPanelsConfigurationIndex
                        )
                      }
                    />
                  )}
                {ioConfiguration?.solarPanelsConfigurations.length > 0 && (
                  <div className="flex justify-center">
                    <RoundedButton
                      icon="pi pi-trash"
                      className="!mb-2"
                      onClick={() =>
                        deleteSolarPanels(selectedSolarPanelsConfigurationIndex)
                      }
                    />
                  </div>
                )}

                <Separator />
                {ioConfiguration.solarPanelsConfigurations.length > 0 && (
                  <>
                    <ContentWithLabel
                      label={
                        t(
                          "configuration.solarPanelsForecastUsedForControlDeviceAttribute"
                        ) + ":"
                      }
                    >
                      <DeviceAttributeSelector
                        installationId={selectedInstallationId}
                        selectedDeviceAttributeId={
                          ioConfiguration.solarPanelsForecastForControlDeviceAttributeId
                        }
                        onDeviceAttributeIdChange={(v) =>
                          setConfigurationProperty(
                            "solarPanelsForecastForControlDeviceAttributeId",
                            v
                          )
                        }
                      />
                    </ContentWithLabel>

                    <ContentWithLabel
                      label={
                        t(
                          "configuration.solarPanelsTotalProductionDeviceAttribute"
                        ) + ":"
                      }
                    >
                      <DeviceAttributeSelector
                        installationId={selectedInstallationId}
                        selectedDeviceAttributeId={
                          ioConfiguration.solarPanelsPowerDeviceAttributeId
                        }
                        onDeviceAttributeIdChange={(v) =>
                          setConfigurationProperty(
                            "solarPanelsPowerDeviceAttributeId",
                            v
                          )
                        }
                      />
                    </ContentWithLabel>
                  </>
                )}
              </Fieldset>
            </div>
            <div className="grow basis-0 p-2 mt-12">
              <div>
                <Fieldset className="bg-slate-700  mt-6">
                  <div className="flex justify-center mt-[-75px]">
                    <img
                      className="w-24"
                      src="/images/battery.png"
                      alt={t("common.battery")}
                    />
                  </div>
                  <div className="flex justify-center text-primary font-bold text-xl">
                    {t("common.battery")}
                  </div>
                  <ContentWithLabel
                    label={
                      t("configuration.batteryStateOfChargeDeviceAttribute") +
                      ":"
                    }
                  >
                    <DeviceAttributeSelector
                      installationId={selectedInstallationId}
                      allowEmpty
                      selectedDeviceAttributeId={
                        ioConfiguration.batteryStateOfChargeDeviceAttributeId ??
                        undefined
                      }
                      onDeviceAttributeIdChange={(v) =>
                        setConfigurationProperty(
                          "batteryStateOfChargeDeviceAttributeId",
                          v
                        )
                      }
                    />
                  </ContentWithLabel>
                  <ContentWithLabel
                    label={t("configuration.batteryPowerDeviceAttribute") + ":"}
                  >
                    <DeviceAttributeSelector
                      installationId={selectedInstallationId}
                      allowEmpty
                      selectedDeviceAttributeId={
                        ioConfiguration.batteryPowerDeviceAttributeId ??
                        undefined
                      }
                      onDeviceAttributeIdChange={(v) =>
                        setConfigurationProperty(
                          "batteryPowerDeviceAttributeId",
                          v
                        )
                      }
                    />
                  </ContentWithLabel>

                  <ContentWithLabel
                    label={t("configuration.batteryCapacity") + ":"}
                  >
                    <div className="p-inputgroup mb-3">
                      <InputNumber
                        value={ioConfiguration.batteryCapacity}
                        maxFractionDigits={2}
                        onChange={(e) =>
                          setConfigurationProperty("batteryCapacity", e.value!)
                        }
                      />
                      <span className="p-inputgroup-addon">kWh</span>
                    </div>
                  </ContentWithLabel>

                  <ContentWithLabel
                    label={t("configuration.batteryMinStateOfCharge") + ":"}
                  >
                    <div className="p-inputgroup mb-3">
                      <InputNumber
                        value={ioConfiguration.batteryMinStateOfCharge}
                        onChange={(e) =>
                          setConfigurationProperty(
                            "batteryMinStateOfCharge",
                            e.value!
                          )
                        }
                      />
                      <span className="p-inputgroup-addon">%</span>
                    </div>
                  </ContentWithLabel>

                  <ContentWithLabel
                    label={
                      t("configuration.batteryAdditionalStateOfChargeBuffer") +
                      ":"
                    }
                  >
                    <div className="p-inputgroup mb-3">
                      <InputNumber
                        value={
                          ioConfiguration.batteryAdditionalStateOfChargeBuffer
                        }
                        onChange={(e) =>
                          setConfigurationProperty(
                            "batteryAdditionalStateOfChargeBuffer",
                            e.value!
                          )
                        }
                      />
                      <span className="p-inputgroup-addon">%</span>
                    </div>
                  </ContentWithLabel>

                  <ContentWithLabel
                    label={t("configuration.batteryMaxChargePower") + ":"}
                  >
                    <div className="p-inputgroup mb-3">
                      <InputNumber
                        value={ioConfiguration.batteryMaxChargePower}
                        onChange={(e) =>
                          setConfigurationProperty(
                            "batteryMaxChargePower",
                            e.value!
                          )
                        }
                      />
                      <span className="p-inputgroup-addon">W</span>
                    </div>
                  </ContentWithLabel>

                  <ContentWithLabel
                    label={t("configuration.batteryMaxDischargePower") + ":"}
                  >
                    <div className="p-inputgroup mb-3">
                      <InputNumber
                        value={ioConfiguration.batteryMaxDischargePower}
                        onChange={(e) =>
                          setConfigurationProperty(
                            "batteryMaxDischargePower",
                            e.value!
                          )
                        }
                      />
                      <span className="p-inputgroup-addon">W</span>
                    </div>
                  </ContentWithLabel>
                </Fieldset>
              </div>
            </div>
            <div className="grow basis-0 p-2 mt-12">
              <Fieldset className=" mt-6">
                <div className="flex justify-center mt-[-75px]">
                  <img
                    className="w-24"
                    src="/images/euro_sign.png"
                    alt={t("configuration.energyPrices")}
                  />
                </div>
                <div className="flex justify-center text-primary font-bold text-xl">
                  {t("configuration.energyPrices")}
                </div>
                <ContentWithLabel
                  label={
                    t("configuration.energyMarketPricesDeviceAttribute") + ":"
                  }
                >
                  <DeviceAttributeSelector
                    installationId={selectedInstallationId}
                    selectedDeviceAttributeId={
                      ioConfiguration.energyMarketPricesDeviceAttributeId
                    }
                    onDeviceAttributeIdChange={(v) =>
                      setConfigurationProperty(
                        "energyMarketPricesDeviceAttributeId",
                        v
                      )
                    }
                  />
                </ContentWithLabel>
                <ContentWithLabel
                  label={
                    t("configuration.staticEnergyMarketPricesDeviceAttribute") +
                    ":"
                  }
                >
                  <DeviceAttributeSelector
                    allowEmpty={true}
                    installationId={selectedInstallationId}
                    selectedDeviceAttributeId={
                      ioConfiguration.energyStaticMarketPricesDeviceAttributeId ??
                      undefined
                    }
                    onDeviceAttributeIdChange={(v) =>
                      setConfigurationProperty(
                        "energyStaticMarketPricesDeviceAttributeId",
                        v
                      )
                    }
                  />
                </ContentWithLabel>
              </Fieldset>
              <div className="mt-20">
                <Fieldset>
                  <div className="flex justify-center mt-[-75px]">
                    <img
                      className="w-24"
                      src="/images/consumption.png"
                      alt={t("configuration.averageConsumption")}
                    />
                  </div>
                  <div className="flex justify-center text-primary font-bold text-xl">
                    {t("configuration.averageConsumption")}
                  </div>
                  <ContentWithLabel
                    label={t("configuration.consumptionDeviceAttribute") + ":"}
                  >
                    <DeviceAttributeSelector
                      installationId={selectedInstallationId}
                      selectedDeviceAttributeId={
                        ioConfiguration.consumptionDeviceAttributeId
                      }
                      onDeviceAttributeIdChange={(v) =>
                        setConfigurationProperty(
                          "consumptionDeviceAttributeId",
                          v
                        )
                      }
                    />
                  </ContentWithLabel>

                  <ContentWithLabel
                    label={
                      t("configuration.consumptionForecastDeviceAttribute") +
                      ":"
                    }
                  >
                    <DeviceAttributeSelector
                      installationId={selectedInstallationId}
                      selectedDeviceAttributeId={
                        ioConfiguration.consumptionForecastDeviceAttributeId
                      }
                      onDeviceAttributeIdChange={(v) =>
                        setConfigurationProperty(
                          "consumptionForecastDeviceAttributeId",
                          v
                        )
                      }
                    />
                  </ContentWithLabel>

                  <ContentWithLabel
                    label={
                      t("configuration.consumptionWithoutEvDeviceAttribute") +
                      ":"
                    }
                  >
                    <DeviceAttributeSelector
                      allowEmpty={true}
                      installationId={selectedInstallationId}
                      selectedDeviceAttributeId={
                        ioConfiguration.consumptionWithoutEvDeviceAttributeId
                      }
                      onDeviceAttributeIdChange={(v) =>
                        setConfigurationProperty(
                          "consumptionWithoutEvDeviceAttributeId",
                          v
                        )
                      }
                    />
                  </ContentWithLabel>

                  <ContentWithLabel
                    label={
                      t("configuration.consumptionEvDeviceAttribute") + ":"
                    }
                  >
                    <DeviceAttributeSelector
                      allowEmpty={true}
                      installationId={selectedInstallationId}
                      selectedDeviceAttributeId={
                        ioConfiguration.consumptionEvDeviceAttributeId ??
                        undefined
                      }
                      onDeviceAttributeIdChange={(v) =>
                        setConfigurationProperty(
                          "consumptionEvDeviceAttributeId",
                          v
                        )
                      }
                    />
                  </ContentWithLabel>
                </Fieldset>
              </div>
            </div>
            <div className="grow basis-0 p-2 mt-12">
              <Fieldset>
                <div className="flex justify-center mt-[-75px]">
                  <img
                    className="w-24"
                    src="/images/electricity_plug.png"
                    alt={t("configuration.evCharging")}
                  />
                </div>
                <div className="flex justify-center text-primary font-bold text-xl">
                  {t("configuration.evCharging")}
                </div>

                <ContentWithLabel
                  label={t("configuration.hasPublicChargingStation") + ":"}
                >
                  <InputSwitch
                    checked={ioConfiguration.hasPublicChargingStation ?? false}
                    onChange={(e) =>
                      setConfigurationProperty(
                        "hasPublicChargingStation",
                        e.value ?? false
                      )
                    }
                  ></InputSwitch>
                </ContentWithLabel>

                <ContentWithLabel
                  label={t("configuration.evTotalPowerDeviceAttribute") + ":"}
                >
                  <DeviceAttributeSelector
                    installationId={selectedInstallationId}
                    selectedDeviceAttributeId={
                      ioConfiguration.evTotalPowerDeviceAttributeId ?? undefined
                    }
                    onDeviceAttributeIdChange={(v) =>
                      setConfigurationProperty(
                        "evTotalPowerDeviceAttributeId",
                        v
                      )
                    }
                    allowEmpty
                  />
                </ContentWithLabel>

                <ContentWithLabel label={t("ev.chargingPrice") + ":"}>
                  <div className="p-inputgroup mb-3">
                    <InputNumber
                      value={ioConfiguration.evChargingPrice}
                      maxFractionDigits={3}
                      onChange={(e) =>
                        setConfigurationProperty("evChargingPrice", e.value!)
                      }
                    />
                    <span className="p-inputgroup-addon">€/kWh</span>
                  </div>
                </ContentWithLabel>
                <ContentWithLabel label={t("ev.chargingThresholdPrice") + ":"}>
                  <div className="p-inputgroup mb-3">
                    <InputNumber
                      value={ioConfiguration.evChargingThresholdPrice}
                      maxFractionDigits={3}
                      onChange={(e) =>
                        setConfigurationProperty(
                          "evChargingThresholdPrice",
                          e.value!
                        )
                      }
                    />
                    <span className="p-inputgroup-addon">€/kWh</span>
                  </div>
                </ContentWithLabel>
                <ContentWithLabel label={t("ev.immediateChargingTime") + ":"}>
                  <div className="p-inputgroup mb-3">
                    <InputNumber
                      value={ioConfiguration.evImmediateChargingTime}
                      maxFractionDigits={0}
                      onChange={(e) =>
                        setConfigurationProperty(
                          "evImmediateChargingTime",
                          e.value!
                        )
                      }
                    />
                    <span className="p-inputgroup-addon">h</span>
                  </div>
                </ContentWithLabel>
              </Fieldset>
            </div>
            <div className="grow basis-0 p-2 mt-12">
              <Fieldset className="mt-6">
                <div className="flex justify-center mt-[-75px]">
                  <img
                    className="w-24"
                    src="/images/transformator.png"
                    alt={t("configuration.grid")}
                  />
                </div>
                <div className="flex justify-center text-primary font-bold text-xl">
                  {t("configuration.grid")}
                </div>
                <ContentWithLabel
                  label={t("configuration.gridPowerDeviceAttribute") + ":"}
                >
                  <DeviceAttributeSelector
                    installationId={selectedInstallationId}
                    selectedDeviceAttributeId={
                      ioConfiguration.gridPowerDeviceAttributeId
                    }
                    onDeviceAttributeIdChange={(v) =>
                      setConfigurationProperty("gridPowerDeviceAttributeId", v)
                    }
                  />
                </ContentWithLabel>

                <ContentWithLabel
                  label={t("configuration.maxGridCapacity") + ":"}
                >
                  <div className="p-inputgroup mb-3">
                    <InputNumber
                      value={ioConfiguration.maxGridCapacity}
                      onChange={(e) =>
                        setConfigurationProperty("maxGridCapacity", e.value!)
                      }
                    />
                    <span className="p-inputgroup-addon">W</span>
                  </div>
                </ContentWithLabel>

                <ContentWithLabel
                  label={t("configuration.maxAcDcConversionPower") + ":"}
                >
                  <div className="p-inputgroup mb-3">
                    <InputNumber
                      value={ioConfiguration.maxDcAcConversionPower}
                      onChange={(e) =>
                        setConfigurationProperty(
                          "maxDcAcConversionPower",
                          e.value!
                        )
                      }
                    />
                    <span className="p-inputgroup-addon">W</span>
                  </div>
                </ContentWithLabel>

                <ContentWithLabel
                  label={t("configuration.takingFromGridTax") + ":"}
                >
                  <div className="p-inputgroup mb-3">
                    <InputNumber
                      value={ioConfiguration.takingFromGridTax}
                      onChange={(e) =>
                        setConfigurationProperty("takingFromGridTax", e.value!)
                      }
                    />
                    <span className="p-inputgroup-addon">€/MWh</span>
                  </div>
                </ContentWithLabel>

                <ContentWithLabel label={t("configuration.VatTax") + ":"}>
                  <div className="p-inputgroup mb-3">
                    <InputNumber
                      value={ioConfiguration.vatTax}
                      onChange={(e) =>
                        setConfigurationProperty("vatTax", e.value!)
                      }
                    />
                    <span className="p-inputgroup-addon">%</span>
                  </div>
                </ContentWithLabel>

                <ContentWithLabel
                  label={t("configuration.capacityTariffLimit") + ":"}
                >
                  <div className="p-inputgroup mb-3">
                    <InputNumber
                      value={ioConfiguration.capacityTariffLimit}
                      onChange={(e) =>
                        setConfigurationProperty(
                          "capacityTariffLimit",
                          e.value!
                        )
                      }
                    />
                    <span className="p-inputgroup-addon">W</span>
                  </div>
                </ContentWithLabel>
                <ContentWithLabel
                  label={t("configuration.defaultCapacityTariffLimit") + ":"}
                >
                  <div className="p-inputgroup mb-3">
                    <InputNumber
                      value={ioConfiguration.defaultCapacityTariffLimit}
                      onChange={(e) =>
                        setConfigurationProperty(
                          "defaultCapacityTariffLimit",
                          e.value!
                        )
                      }
                    />
                    <span className="p-inputgroup-addon">W</span>
                  </div>
                </ContentWithLabel>
                <ContentWithLabel
                  label={t("configuration.gridPowerPeaksDeviceAttribute") + ":"}
                >
                  <DeviceAttributeSelector
                    installationId={selectedInstallationId}
                    selectedDeviceAttributeId={
                      ioConfiguration.gridPowerPeaksDeviceAttributeId ??
                      undefined
                    }
                    onDeviceAttributeIdChange={(v) =>
                      setConfigurationProperty(
                        "gridPowerPeaksDeviceAttributeId",
                        v
                      )
                    }
                    allowEmpty
                  />
                </ContentWithLabel>
                <ContentWithLabel
                  label={t("configuration.costOfExtraCapacity") + ":"}
                >
                  <div className="p-inputgroup mb-3">
                    <InputNumber
                      value={ioConfiguration.costOfExtraCapacity}
                      onChange={(e) =>
                        setConfigurationProperty(
                          "costOfExtraCapacity",
                          e.value!
                        )
                      }
                    />
                    <span className="p-inputgroup-addon">€/kW</span>
                  </div>
                </ContentWithLabel>
              </Fieldset>
            </div>

            <div className="grow basis-0 p-2 mt-12">
              <Fieldset>
                <div className="flex justify-center mt-[-75px]">
                  <img
                    className="w-24"
                    src="/images/consumption.png"
                    alt={t("configuration.heating")}
                  />
                </div>
                <div className="flex justify-center text-primary font-bold text-xl">
                  {t("configuration.heating")}
                </div>
                <ContentWithLabel label={t("configuration.coordinates") + ":"}>
                  <div className="p-inputgroup mb-3">
                    <InputNumber
                      value={ioConfiguration.latitude}
                      mode="decimal"
                      maxFractionDigits={15}
                      onChange={(e) =>
                        setConfigurationProperty("latitude", e.value!)
                      }
                    />
                    <span className="p-inputgroup-addon">°N</span>
                  </div>
                  <div className="p-inputgroup mb-3">
                    <InputNumber
                      value={ioConfiguration.longitude}
                      mode="decimal"
                      maxFractionDigits={15}
                      onChange={(e) =>
                        setConfigurationProperty("longitude", e.value!)
                      }
                    />
                    <span className="p-inputgroup-addon">°E</span>
                  </div>
                </ContentWithLabel>
                <ContentWithLabel
                  label={
                    t(
                      "configuration.outsideTemperatureForecastDeviceAttribute"
                    ) + ":"
                  }
                >
                  <DeviceAttributeSelector
                    installationId={selectedInstallationId}
                    selectedDeviceAttributeId={
                      ioConfiguration.outsideTemperatureForecastDeviceAttributeId ??
                      undefined
                    }
                    onDeviceAttributeIdChange={(v) =>
                      setConfigurationProperty(
                        "outsideTemperatureForecastDeviceAttributeId",
                        v
                      )
                    }
                    allowEmpty
                  />
                </ContentWithLabel>
              </Fieldset>
            </div>

            <div className="grow basis-0 p-2 mt-12">
              <Fieldset>
                <div className="flex justify-center mt-[-75px]">
                  <img
                    className="w-24"
                    src="/images/electricity_plug.png"
                    alt={t("configuration.controlledOutput")}
                  />
                </div>
                <div className="flex justify-center text-primary font-bold text-xl">
                  {t("configuration.controlledOutput")}
                </div>
                <ContentWithLabel
                  label={t("configuration.gridAcPowerDeviceAttribute") + ":"}
                >
                  <DeviceAttributeSelector
                    installationId={selectedInstallationId}
                    selectedDeviceAttributeId={
                      ioConfiguration.controlledDeviceAttributeId
                    }
                    onDeviceAttributeIdChange={(v) =>
                      setConfigurationProperty("controlledDeviceAttributeId", v)
                    }
                  />
                </ContentWithLabel>

                <ContentWithLabel
                  label={
                    t("configuration.selfConsumptionSwitchDeviceAttribute") +
                    ":"
                  }
                >
                  <DeviceAttributeSelector
                    installationId={selectedInstallationId}
                    selectedDeviceAttributeId={
                      ioConfiguration.selfConsumptionSwitchDeviceAttributeId ??
                      undefined
                    }
                    onDeviceAttributeIdChange={(v) => {
                      console.info(
                        "About to set selfConsumptionSwitchDeviceAttributeId to ",
                        v
                      );
                      setConfigurationProperty(
                        "selfConsumptionSwitchDeviceAttributeId",
                        v
                      );
                    }}
                    allowEmpty
                  />
                </ContentWithLabel>

                <ContentWithLabel label={t("configuration.controlMode") + ":"}>
                  <div className="flex flex-row align-items-center">
                    <span>{t("configuration.controlModeManual")}</span>
                    <InputSwitch
                      checked={ioConfiguration.isAutoModeEnabled}
                      className="mx-2"
                      onChange={(e) =>
                        setConfigurationProperty(
                          "isAutoModeEnabled",
                          Boolean(e.value)
                        )
                      }
                    ></InputSwitch>
                    <span>{t("configuration.controlModeAuto")}</span>
                  </div>
                </ContentWithLabel>
              </Fieldset>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
