import { DataTable } from "primereact/datatable";
import { useInstallationSelector } from "../ui/InstallationContext";
import { Loader } from "../ui/Loader";
import { Column } from "primereact/column";
import {
  useUsersQuery,
  useInviteUserMutation,
  useInvitationsQuery,
} from "../../queries/charging-admin.query";
import { Button } from "primereact/button";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useToast } from "../ui/ToastContext";
import { ConditionalCheckmark } from "../ui/ConditionalCheckmark";
import { format } from "date-fns";

export function Users() {
  const { selectedInstallationId } = useInstallationSelector();
  const { data, isLoading } = useUsersQuery(selectedInstallationId);
  const { data: invitations, isLoading: isInvitationsLoading } =
    useInvitationsQuery(selectedInstallationId);
  const inviteUserMutation = useInviteUserMutation(selectedInstallationId);
  const toast = useToast();

  const [inviteDialogVisible, setInviteDialogVisible] = useState(false);
  const [email, setEmail] = useState("");

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Loader spinner />
      </div>
    );
  }

  const inviteUser = () => {
    setInviteDialogVisible(true);
  };

  const sendInvite = async () => {
    try {
      await inviteUserMutation.mutateAsync(email);
      setInviteDialogVisible(false);
      setEmail("");
      toast.current?.show({
        severity: "success",
        detail: "Invite sent",
      });
    } catch (error: any) {
      toast.current?.show({
        severity: "error",
        detail: "Error while sending invite",
      });
    }
  };

  return (
    <div>
      <div className="flex justify-end mb-4">
        <Button
          label="Invite user to charge at this installation"
          icon="pi pi-plus"
          onClick={inviteUser}
        />
      </div>
      {data && (
        <div className="flex flex-col gap-3">
          <h2 className="text-2xl font-bold">Users</h2>
          <DataTable value={data}>
            <Column field="id" header="Id"></Column>
            <Column field="email" header="Email"></Column>
          </DataTable>
        </div>
      )}

      {invitations && (
        <div className="flex flex-col gap-3 mt-4">
          <h2 className="text-2xl font-bold">Invitations</h2>
          <DataTable value={invitations}>
            <Column field="email" header="Email"></Column>
            <Column
              field="isAccepted"
              header="Is accepted"
              body={(invitation) => (
                <ConditionalCheckmark show={invitation.isAccepted} />
              )}
            ></Column>
            <Column
              field="expirationDate"
              header="Expiration date"
              body={(invitation) =>
                format(invitation.expirationDate, "yyyy-MM-dd HH:mm")
              }
            ></Column>
          </DataTable>
        </div>
      )}

      <Dialog
        visible={inviteDialogVisible}
        header="Invite user"
        onHide={() => setInviteDialogVisible(false)}
      >
        <div className="flex flex-col gap-3">
          <InputText
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="shadow-sm"
          />
          <Button label="Send invite" onClick={sendInvite} disabled={!email} />
        </div>
      </Dialog>
    </div>
  );
}
