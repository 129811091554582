import { Navigate } from "react-router";
import { EnergyFlow } from "../components/dashboard/EnergyFlow";
import { useInstallationSelector } from "../components/ui/InstallationContext";
import QuickGuideButton from "../components/ui/QuickGuideButton";

export function Dashboard() {
  const { selectedInstallationId, hasInstallation } = useInstallationSelector();

  if (!hasInstallation) {
    // redirect to public charging
    return <Navigate to="/public-charging" />;
  }

  return (
    <div className="w-full">
      {Boolean(selectedInstallationId) && (
        <>
          <div className="mr-3 relative">
            <QuickGuideButton buttonLink="https://app.storylane.io/share/hsc9w9uigga1"></QuickGuideButton>
          </div>
          <EnergyFlow installationId={selectedInstallationId} />
        </>
      )}
    </div>
  );
}
