import { InputText } from "primereact/inputtext";
import { ChargerConfiguration } from "../../queries/models/ev-configuration/charger-configuration.model";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputNumber } from "primereact/inputnumber";
import { DeviceAttributeSelector } from "../configuration/io/DeviceAttributeSelector";
import { useInstallationSelector } from "../ui/InstallationContext";
import { InputSwitch } from "primereact/inputswitch";

interface ChargerEditFormProps {
  value: ChargerConfiguration;
  onChange: (charger: ChargerConfiguration) => void;
}

export function ChargerEditForm({ value, onChange }: ChargerEditFormProps) {
  const { t } = useTranslation();
  const { selectedInstallationId } = useInstallationSelector();

  const [name, setName] = useState(value.name);
  const [qrCode, setQrCode] = useState(value.qrCode);
  const [maxChargingPower, setMaxChargingPower] = useState(
    value.maxChargingPower
  );
  const [pluggedInDeviceAttributeId, setPluggedInDeviceAttributeId] = useState(
    value.pluggedInDeviceAttributeId
  );
  const [consumptionDeviceAttributeId, setConsumptionDeviceAttributeId] =
    useState(value.consumptionDeviceAttributeId);
  const [
    energyDeliveredDeviceAttributeId,
    setEnergyDeliveredDeviceAttributeId,
  ] = useState(value.energyDeliveredDeviceAttributeId);
  const [setpointDeviceAttributeId, setSetpointDeviceAttributeId] = useState(
    value.setpointDeviceAttributeId
  );
  const [isEnabled, setIsEnabled] = useState(value.isEnabled);
  const [isPublic, setIsPublic] = useState(value.isPublic);
  useEffect(() => {
    value.name = name;
    value.qrCode = qrCode;
    value.maxChargingPower = maxChargingPower;
    value.pluggedInDeviceAttributeId = pluggedInDeviceAttributeId;
    value.consumptionDeviceAttributeId = consumptionDeviceAttributeId;
    value.energyDeliveredDeviceAttributeId = energyDeliveredDeviceAttributeId;
    value.setpointDeviceAttributeId = setpointDeviceAttributeId;
    value.isEnabled = isEnabled;
    value.isPublic = isPublic;

    onChange(value);
  }, [
    value,
    name,
    qrCode,
    maxChargingPower,
    pluggedInDeviceAttributeId,
    consumptionDeviceAttributeId,
    energyDeliveredDeviceAttributeId,
    setpointDeviceAttributeId,
    isEnabled,
    isPublic,
    onChange,
  ]);

  return (
    <div className="flex flex-col grey-inputs" style={{ width: 600 }}>
      <label className="mt-2">{t("common.name")}</label>
      <InputText value={name} onChange={(v) => setName(v.target.value)} />

      <label className="mt-2">QR code</label>
      <InputText value={qrCode} onChange={(v) => setQrCode(v.target.value)} />

      <label className="mt-2">Max charging power</label>
      <InputNumber
        value={maxChargingPower}
        onChange={(v) => setMaxChargingPower(v.value ?? 0)}
      />

      <label className="mt-2">Plugged in device attribute</label>
      <DeviceAttributeSelector
        installationId={selectedInstallationId}
        selectedDeviceAttributeId={pluggedInDeviceAttributeId}
        onDeviceAttributeIdChange={(v) => setPluggedInDeviceAttributeId(v)}
      />

      <label className="mt-2">Consumption device attribute</label>
      <DeviceAttributeSelector
        installationId={selectedInstallationId}
        selectedDeviceAttributeId={consumptionDeviceAttributeId}
        onDeviceAttributeIdChange={(v) => setConsumptionDeviceAttributeId(v)}
      />

      <label className="mt-2">Energy delivered device attribute</label>
      <DeviceAttributeSelector
        installationId={selectedInstallationId}
        selectedDeviceAttributeId={energyDeliveredDeviceAttributeId}
        onDeviceAttributeIdChange={(v) =>
          setEnergyDeliveredDeviceAttributeId(v)
        }
      />

      <label className="mt-2">Setpoint device attribute</label>
      <DeviceAttributeSelector
        installationId={selectedInstallationId}
        selectedDeviceAttributeId={setpointDeviceAttributeId}
        onDeviceAttributeIdChange={(v) => setSetpointDeviceAttributeId(v)}
      />

      <label className="mt-2">Enabled</label>
      <InputSwitch
        checked={isEnabled}
        onChange={(v) => setIsEnabled(Boolean(v.target.value))}
      />

      <label className="mt-2">Public</label>
      <InputSwitch
        checked={isPublic}
        onChange={(v) => setIsPublic(Boolean(v.target.value))}
      />
    </div>
  );
}
