import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class MaintenanceService extends BaseService {
  async getWebVersion(): Promise<number> {
    return this.http
      .get<number>(this.endpointPath + "/web-version")
      .then((res) => res.data);
  }
}

export const maintenanceService = new MaintenanceService(
  API_ENDPOINTS.MAINTENANCE
);
