import { AxiosResponse } from "axios";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class PublicChargingService extends BaseService {
  async acceptInvitation(code: string): Promise<AxiosResponse<any, any>> {
    const url = `${this.endpointPath}/invite/${code}`;

    return this.http.post(url).then((res) => res.data);
  }
}

export const publicChargingService = new PublicChargingService(
  API_ENDPOINTS.PUBLIC_CHARGING
);
