import { useEffect } from "react";
import { useAuth } from "oidc-react";
import { useNavigate } from "react-router-dom";

export function AuthCallback() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.userData) {
      const returnTo = sessionStorage.getItem("returnTo") || "/";
      sessionStorage.removeItem("returnTo");
      navigate(returnTo, { replace: true });
    }
  }, [auth.userData, navigate]);

  return <div></div>;
}
