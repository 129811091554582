import { useQuery } from "react-query";
import { maintenanceService } from "../services/maintenance-service";

export function useWebVersionQuery(localVersion: number) {
  return useQuery<number>(
    ["web-version"],
    () => maintenanceService.getWebVersion(),
    {
      refetchInterval: 60 * 1000, // 1 minute
      initialData: localVersion,
    }
  );
}
