import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCalendarDays,
  faChartSimple,
  faGaugeHigh,
  faGear,
  faLanguage,
  faMagnifyingGlassDollar,
  faServer,
  faSliders,
  faCar,
  faHouse,
  faUsers,
  faChargingStation,
} from "@fortawesome/free-solid-svg-icons";
import { useWindowSize } from "../../hooks/use-window-size";
import { Permissions } from "../../utils/permissions";
import { SideBarNavigationDesktop } from "./side-bar-nav-desktop";
import { SideBarNavigationMobile } from "./side-bar-nav-mobile";
import { useInstallationSelector } from "../ui/InstallationContext";
import { useIOConfigurationQuery } from "../../queries/configuration.query";
import { useMemo } from "react";

export interface MenuElement {
  to: string;
  label: string;
  icon: IconProp;
  permission?: Permissions;
}

export function SideBarNavigation() {
  const windowSize = useWindowSize();
  const { selectedInstallationId, hasInstallation } = useInstallationSelector();
  const configurationQuery = useIOConfigurationQuery(selectedInstallationId);

  const menuElements: MenuElement[] = useMemo(() => {
    let elements = [
      {
        to: "dashboard",
        label: "dashboard.dashboard",
        icon: faGaugeHigh,
        permission: "Dashboard",
      },
      {
        to: "overview",
        label: "overview.overview",
        icon: faMagnifyingGlassDollar,
        permission: "Overview",
      },
      {
        to: "data-charts",
        label: "dataCharts.dataCharts",
        icon: faChartSimple,
        permission: "DataCharts",
      },
      {
        to: "control",
        label: "control.control",
        icon: faSliders,
        permission: "Control",
      },
      {
        to: "ev-configuration",
        label: "EV Configuration",
        icon: faCar,
        permission: "EV",
      },
      {
        to: "car-configuration",
        label: "Car Configuration",
        icon: faCar,
        permission: "EvCarsAdmin",
      },
      {
        to: "ev-charging",
        label: "EV Charging",
        icon: faCar,
        permission: "EV",
      },
      {
        to: "public-charging-admin",
        label: "Charging admin",
        icon: faChargingStation,
        permission: "PublicChargingAdmin",
      },
      {
        to: "consumption-plans",
        label: "consumptionPlans.consumptionPlans",
        icon: faCalendarDays,
        permission: "ConsumptionPlans",
      },
      {
        to: "devices",
        label: "configuration.devices",
        icon: faServer,
        permission: "Devices",
      },
      {
        to: "io",
        label: "configuration.io",
        icon: faGear,
        permission: "IO",
      },
      {
        to: "installations",
        label: "configuration.installations",
        icon: faHouse,
        permission: "Installations",
      },
      {
        to: "translations",
        label: "translations.translations",
        icon: faLanguage,
        permission: "Translations",
      },
      {
        to: "users",
        label: "users.users",
        icon: faUsers,
        permission: "Users",
      },
    ] as MenuElement[];

    if (!configurationQuery.data?.evTotalPowerDeviceAttributeId) {
      elements.splice(
        elements.findIndex((e) => e.to === "ev-charging"),
        1
      );
    }

    if (!configurationQuery.data?.hasPublicChargingStation) {
      elements.splice(
        elements.findIndex((e) => e.to === "public-charging"),
        1
      );
    }

    return elements;
  }, [configurationQuery.data]);

  return (
    <>
      {(hasInstallation || configurationQuery.data) && (
        <>
          {windowSize.lg ? (
            <SideBarNavigationDesktop menuElements={menuElements} />
          ) : (
            <SideBarNavigationMobile menuElements={menuElements} />
          )}
        </>
      )}
    </>
  );
}
