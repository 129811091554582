import { useEffect, useRef, useState } from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router";
import { Dashboard } from "./pages/Dashboard";
import { DataCharts } from "./pages/DataCharts";
import { Control } from "./pages/Control";
import { TopBarNavigation } from "./components/navigation/top-bar-navigation";
import { SideBarNavigation } from "./components/navigation/side-bar-nav";

import { Toast } from "primereact/toast";
import { locale, addLocale } from "primereact/api";
import { ToastContextProvider } from "./components/ui/ToastContext";
import { AxiosInterceptor } from "./components/ui/AxiosInterceptorsWrapper";
import { Devices } from "./pages/Devices";
import { IO } from "./pages/IO";
import { useAtom } from "jotai";
import { sideBarAtom } from "./atoms/side-bar-atom";
import { classNames } from "primereact/utils";
import { Overview } from "./pages/Overview";
import { InstallationContextProvider } from "./components/ui/InstallationContext";
import { ConsumptionPlans } from "./pages/ConsumptionPlans";
import { Translations } from "./pages/Translations";
import { useTranslation } from "react-i18next";
import { useCultureTranslationsJsonQuery } from "./queries/translations.query";
import { EvChargingUser } from "./pages/EvChargingUser";
import { Installations } from "./pages/Installations";
import { Users } from "./pages/Users";
import AppVersionChecker from "./components/AppVersionChecker";
import { Settings } from "./pages/Settings";
import { PublicChargingAdmin } from "./pages/PublicChargingAdmin";
import { EvConfiguration } from "./pages/EvConfiguration";
import { CarConfigurationPage } from "./pages/CarConfiguration";
import { PublicChargingInvite } from "./pages/PublicCharging";
import { AuthCallback } from "./pages/AuthCallback";
import { useAuthRedirect } from "./hooks/use-auth-redirect";

function App() {
  const toast = useRef<Toast>(null);

  useAuthRedirect();

  addLocale("nl", {
    firstDayOfWeek: 1,
    dateFormat: "dd.mm.yy",
  });

  locale("nl");

  const [sidebarState] = useAtom(sideBarAtom);

  const { i18n } = useTranslation();

  const [rerenderer, setRerenderer] = useState(i18n.language);
  const languageQuery = useCultureTranslationsJsonQuery(i18n.language);

  useEffect(() => {
    if (!languageQuery.isLoading && languageQuery.isFetched && i18n.language) {
      i18n.addResourceBundle(
        i18n.language,
        "translation",
        JSON.parse(languageQuery.data ?? "")
      );
      setRerenderer(i18n.language);
    }
  }, [
    rerenderer,
    i18n,
    languageQuery,
    i18n.language,
    languageQuery.isFetched,
    languageQuery.isLoading,
  ]);

  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      <Toast ref={toast} />

      <AxiosInterceptor>
        <ToastContextProvider toastRef={toast}>
          <InstallationContextProvider>
            <AppVersionChecker />
            <TopBarNavigation></TopBarNavigation>
            <SideBarNavigation></SideBarNavigation>
            <div className="pt-14 justify-content-end flex">
              <div
                className={classNames(
                  "w-full transition-duration-150",
                  sidebarState.expanded
                    ? "lg:pl-56 pl-0"
                    : "w-full lg:pl-20 pr-0 lg:pr-1 pl-0 lg:pb-0 pb-12"
                )}
              >
                <Routes>
                  <Route path="/" element={<Navigate to="dashboard" />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="data-charts" element={<DataCharts />} />
                  <Route path="control" element={<Control />} />
                  <Route
                    path="ev-configuration"
                    element={<EvConfiguration />}
                  />
                  <Route
                    path="car-configuration"
                    element={<CarConfigurationPage />}
                  />
                  <Route path="ev-charging" element={<EvChargingUser />} />
                  <Route
                    path="public-charging-admin"
                    element={<PublicChargingAdmin />}
                  />
                  <Route
                    path="public-charging/invite/:code"
                    element={<PublicChargingInvite />}
                  />
                  <Route path="devices" element={<Devices />} />
                  <Route path="io" element={<IO />} />
                  <Route path="overview" element={<Overview />} />
                  <Route path="installations" element={<Installations />} />
                  <Route
                    path="consumption-plans"
                    element={<ConsumptionPlans />}
                  />
                  <Route path="translations" element={<Translations />} />
                  <Route path="users" element={<Users />} />
                  <Route path="auth-callback" element={<AuthCallback />} />
                </Routes>
              </div>
            </div>
          </InstallationContextProvider>
        </ToastContextProvider>
      </AxiosInterceptor>
    </>
  );
}

export default App;
