import { useMutation, useQuery, useQueryClient } from "react-query";
import { AdminChargerInfo } from "./models/public-charging/admin-charger-info.model";
import { chargingAdminService } from "../services/charging-admin.service";
import { ChargingUserInfo } from "./models/public-charging/charging-user-info.model";
import { ChargingStationInvitationInfo } from "./models/public-charging/charging-station-invitation-info.model";

export function useChargersQuery(installationId: number) {
  return useQuery<AdminChargerInfo[]>(
    ["admin-chargers", installationId],
    () => chargingAdminService.getChargers(installationId),
    { enabled: Boolean(installationId) }
  );
}

export function useUsersQuery(installationId: number) {
  return useQuery<ChargingUserInfo[]>(
    ["charging-users", installationId],
    () => chargingAdminService.getUsers(installationId),
    { enabled: Boolean(installationId) }
  );
}

export function useInvitationsQuery(installationId: number) {
  return useQuery<ChargingStationInvitationInfo[]>(
    ["charging-invitations", installationId],
    () => chargingAdminService.getInvitations(installationId),
    { enabled: Boolean(installationId) }
  );
}

export function useInviteUserMutation(installationId: number) {
  const queryClient = useQueryClient();
  return useMutation<void, unknown, string>(
    (email) => chargingAdminService.inviteUser(installationId, email),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["charging-users", installationId]);
      },
    }
  );
}
