import { AxiosResponse } from "axios";
import { useAuth } from "oidc-react";
import { useEffect, useState } from "react";

import AxiosInstance from "../../services/request";
import { handleDates } from "../../utils/serialize-date-helpers";

import i18n from "i18next";

const AxiosInterceptor = ({ children }: { children: any }) => {
  const [isSet, setIsSet] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    const authInterceptor = (config: any) => {
      const header = auth.userData?.access_token
        ? `Bearer ${auth.userData?.access_token}`
        : null;

      config.headers = {
        ...config.headers,
        Authorization: header ?? "",
      };
      return config;
    };

    const languageInterceptor = (config: any) => {
      config.headers = {
        ...config.headers,
        "Language-Culture": i18n.language,
      };
      return config;
    };

    const errInterceptor = (error: any) => {
      return Promise.reject(error);
    };

    const passThroughInterceptor = (response: AxiosResponse<any, any>) =>
      response;

    const authErrorInterceptor = (error: any) => {
      if (
        (error.response.status === 401 || error.response.status === 403) &&
        auth.userData?.access_token
      ) {
        window.location.href = "/";
        return;
      }
      return Promise.reject(error);
    };

    const interceptor = AxiosInstance.interceptors.request.use(
      authInterceptor,
      errInterceptor
    );

    const langInterceptor = AxiosInstance.interceptors.request.use(
      languageInterceptor,
      errInterceptor
    );

    const responseErrorInterceptor = AxiosInstance.interceptors.response.use(
      passThroughInterceptor,
      authErrorInterceptor
    );

    const datesInterceptor = AxiosInstance.interceptors.response.use(
      (response) => {
        handleDates(response.data);
        return response;
      }
    );

    setIsSet(Boolean(auth.userData?.access_token));

    return () => {
      AxiosInstance.interceptors.request.eject(interceptor);
      AxiosInstance.interceptors.response.eject(responseErrorInterceptor);
      AxiosInstance.interceptors.response.eject(datesInterceptor);
      AxiosInstance.interceptors.request.eject(langInterceptor);
    };
  }, [auth.userData?.access_token]);

  return isSet && children;
};

export default AxiosInstance;
export { AxiosInterceptor };
