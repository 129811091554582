import { useParams } from "react-router";
import { usePublicChargingInvitationAcceptMutation } from "../queries/public-charging.query";
import { useEffect, useState } from "react";

export function PublicChargingInvite() {
  const { code } = useParams();
  const acceptInvitationMutation = usePublicChargingInvitationAcceptMutation();

  const [invitationProcessed, setInvitationProcessed] = useState(false);
  const [invitationAccepted, setInvitationAccepted] = useState(false);

  useEffect(() => {
    async function acceptInvitation() {
      try {
        if (!code) {
          throw new Error("No invitation code provided");
        }

        await acceptInvitationMutation.mutateAsync(code);
        setInvitationProcessed(true);
        setInvitationAccepted(true);
      } catch (error) {
        setInvitationProcessed(true);
        setInvitationAccepted(false);
      }
    }

    if (code) {
      console.info("Accepting invitation");
      acceptInvitation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col shadow-md mt-3 p-5 md:p-7 mb-4 rounded-3xl sm:w-[400px] w-[300px]">
        {!invitationProcessed && (
          <div className="text-center">Accepting invitation...</div>
        )}
        {invitationProcessed && (
          <div>
            {invitationAccepted ? (
              <div className="text-center text-primary">
                Invitation accepted. You can continue in the app.
              </div>
            ) : (
              <div className="text-center text-price-red">
                Error processing the invitation. Please contact the
                administrator.
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
