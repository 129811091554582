import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useToast } from "../components/ui/ToastContext";
import { t } from "i18next";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Loader } from "../components/ui/Loader";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import {
  useCarsQuery,
  useCreateCarMutation,
  useDeleteCarMutation,
  useUpdateCarMutation,
} from "../queries/car-configuration.query";
import { CarConfiguration } from "../queries/models/car-configuration/car-configuration.model";
import { Dropdown } from "primereact/dropdown";
import { useUsersQuery } from "../queries/users.query";
import { CarEditForm } from "../components/configuration/car-configuration/CarEditForm";

export function CarConfigurationPage() {
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>();

  const [selectedCar, setSelectedCar] = useState<
    CarConfiguration | undefined
  >();
  const [carDialogVisible, setCarDialogVisible] = useState(false);

  const { data, isLoading } = useCarsQuery(selectedUserId);
  const { data: users } = useUsersQuery();

  const carSaveMutation = useUpdateCarMutation(selectedUserId!);

  const carCreateMutation = useCreateCarMutation(selectedUserId!);

  const carDeleteMutation = useDeleteCarMutation(selectedUserId!);

  const queryClient = useQueryClient();

  const toast = useToast();

  const userOptions = useMemo(
    () =>
      users?.map((u) => ({
        label: u.email,
        value: u.id,
      })),
    [users]
  );

  function showAddCarDialog() {
    setSelectedCar({
      id: 0,
      name: "",
      maxChargingPower: 0,
      capacity: 0,
      userId: selectedUserId,
    } as CarConfiguration);

    setCarDialogVisible(true);
  }

  function hideCarDialog() {
    setSelectedCar(undefined);
    setCarDialogVisible(false);
  }

  async function saveCar() {
    if (selectedCar) {
      if (!selectedCar.id) {
        await carCreateMutation.mutateAsync(selectedCar);
        await queryClient.invalidateQueries("configuration-cars");
        hideCarDialog();
      } else {
        carSaveMutation.mutate(selectedCar, {
          onSuccess: async () => {
            await queryClient.invalidateQueries("configuration-cars");
            hideCarDialog();
          },
          onError: (error: any) => {
            console.info("Error!", error);
            toast.current?.show({
              severity: "error",
              detail: t(error.response?.data) ?? error.message,
            });
          },
        });
      }
    }
  }

  async function deleteCar(id: number) {
    confirmDialog({
      message: "Are you sure you want to delete this car?",
      accept: () => doDeleteCar(id),
    });
  }

  async function doDeleteCar(id: number) {
    await carDeleteMutation.mutateAsync(id);
    await queryClient.invalidateQueries("configuration-cars");
  }

  function showEditDialog(id: number) {
    const car = data?.find((c) => c.id === id);

    if (car) {
      setSelectedCar(car);
      setCarDialogVisible(true);
    }
  }

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Loader spinner />
      </div>
    );
  }

  return (
    <div>
      <div className="flex w-64 mt-2">
        <Dropdown
          value={selectedUserId}
          options={userOptions}
          onChange={(e) => setSelectedUserId(e.value)}
          optionLabel="label"
          placeholder="Select User"
          className="shadow-sm"
        />
      </div>
      {data && (
        <>
          <div>
            <Button
              label="Add Car"
              className="p-button p-button-success !my-2 !mb-3"
              onClick={showAddCarDialog}
            />
          </div>
          <div>
            <DataTable
              value={data}
              onRowClick={(e) =>
                showEditDialog((e.data as CarConfiguration).id)
              }
              rowClassName={() => "cursor-pointer"}
            >
              <Column field="id" header="Id"></Column>
              <Column field="name" header="Name"></Column>
              <Column
                field="maxChargingPower"
                header="Max Charging Power"
              ></Column>
              <Column field="capacity" header="Capacity"></Column>
            </DataTable>
          </div>
        </>
      )}
      <Dialog
        visible={carDialogVisible}
        header="Car"
        onHide={() => hideCarDialog()}
      >
        {selectedCar && (
          <div className="flex flex-col">
            <CarEditForm
              value={selectedCar}
              onChange={(v) => setSelectedCar(v)}
            />

            <Button
              label={t("common.save")}
              className="p-button p-button-success !mt-2"
              onClick={saveCar}
            />
          </div>
        )}
      </Dialog>
      <ConfirmDialog />
    </div>
  );
}
