import { AxiosResponse } from "axios";
import { CarConfiguration } from "../queries/models/car-configuration/car-configuration.model";
import { CreateCar } from "../queries/models/car-configuration/create-car.model";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";
import { UpdateCar } from "../queries/models/car-configuration/update-car.model";

class CarConfigurationService extends BaseService {
  async getCars(userId: number): Promise<CarConfiguration[]> {
    const url = `${this.endpointPath}/${userId}`;

    return this.http.get<CarConfiguration[]>(url).then((res) => res.data);
  }

  async addCar(userId: number, model: CreateCar): Promise<CarConfiguration> {
    const url = `${this.endpointPath}/${userId}`;

    return this.http
      .post<CreateCar, AxiosResponse<CarConfiguration>>(url, model)
      .then((res) => res.data);
  }

  async updateCar(userId: number, model: UpdateCar): Promise<CarConfiguration> {
    const url = `${this.endpointPath}/${userId}/${model.id}`;

    return this.http
      .put<UpdateCar, AxiosResponse<CarConfiguration>>(url, model)
      .then((res) => res.data);
  }

  async deleteCar(userId: number, id: number): Promise<void> {
    const url = `${this.endpointPath}/${userId}/${id}`;

    return this.http.delete(url).then((res) => res.data);
  }
}

export const carConfigurationService = new CarConfigurationService(
  API_ENDPOINTS.CAR_CONFIGURATION
);
