import { useTranslation } from "react-i18next";
import { CarConfiguration } from "../../../queries/models/car-configuration/car-configuration.model";
import { useEffect, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";

interface CarEditFormProps {
  value: CarConfiguration;
  onChange: (value: CarConfiguration) => void;
}

export function CarEditForm({ value, onChange }: CarEditFormProps) {
  const { t } = useTranslation();

  const [name, setName] = useState(value.name);
  const [maxChargingPower, setMaxChargingPower] = useState(
    value.maxChargingPower
  );
  const [capacity, setCapacity] = useState(value.capacity);
  const [oldId, setOldId] = useState(value.oldId);

  useEffect(() => {
    value.name = name;
    value.maxChargingPower = maxChargingPower;
    value.capacity = capacity;
    value.oldId = oldId;
    onChange(value);
  }, [value, name, maxChargingPower, capacity, oldId, onChange]);

  return (
    <div className="flex flex-col grey-inputs" style={{ width: 600 }}>
      <label className="mt-2">{t("common.name")}</label>
      <InputText value={name} onChange={(e) => setName(e.target.value)} />
      <label className="mt-2">Max charging power [W]</label>
      <InputNumber
        value={maxChargingPower}
        onChange={(e) => setMaxChargingPower(e.value ?? 0)}
      />
      <label className="mt-2">Capacity [Wh]</label>
      <InputNumber
        value={capacity}
        onChange={(e) => setCapacity(e.value ?? 0)}
      />
      <label className="mt-2">Old Id [GUID]</label>
      <InputText value={oldId} onChange={(e) => setOldId(e.target.value)} />
    </div>
  );
}
