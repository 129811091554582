import { useMutation } from "react-query";
import { publicChargingService } from "../services/public-charging.service";

export function usePublicChargingInvitationAcceptMutation() {
  return useMutation(
    (code: string) => publicChargingService.acceptInvitation(code),
    {
      retry: false,
    }
  );
}
