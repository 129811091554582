import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./fonts/Kanit/Kanit-Regular.ttf"; // custom font
import "./assets/styles/theme.css"; //custom theme
import "primereact/resources/primereact.min.css"; // core css
import "primeicons/primeicons.css"; // icons
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { AuthProvider } from "oidc-react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const oidcConfig = {
  authority: process.env.REACT_APP_SSO_URL,
  clientId: "EcoScout.React",
  redirectUri: window.location.origin + "/auth-callback",
  responseType: "code",
  scope: "openid profile api.access custom.permissions custom.installations",
  automaticSilentRenew: true,
};

i18n.use(initReactI18next).init({
  lng: "en",
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <AuthProvider {...oidcConfig}>
        <App />
      </AuthProvider>
    </QueryClientProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
