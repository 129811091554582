export const API_ENDPOINTS: Record<string, string> = {
  AUTHENTICATION: "security",
  DASHBOARD: "dashboard",
  OVERVIEW: "overview",
  DATA_ANALYSIS: "data-analysis",
  CONTROL: "control",
  CONFIGURATION: "configuration",
  CONSUMPTION_PLANS: "consumption-plans",
  TRANSLATIONS: "translations",
  EV: "ev",
  USERS: "security/users",
  ROLES: "security/roles",
  MAINTENANCE: "maintenance",
  EV_CHARGING: "ev-charging",
  SETTINGS: "settings",
  CHARGING_ADMIN: "charging-admin",
  EV_CONFIGURATION: "ev-configuration",
  CAR_CONFIGURATION: "ev-configuration/cars",
  PUBLIC_CHARGING: "public-charging",
};
